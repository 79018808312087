import Vue from 'vue';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import './components/Icon/init-icon';

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
  mounted() {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event('render-event'));
  },
}).$mount('#app');
