import Vue from 'vue';

import Icon from './index.vue';

Vue.component('Icon', Icon);
const requireAll = (requireContext: __WebpackModuleApi.RequireContext) => {
  requireContext.keys().forEach((fileName: string) => {
    requireContext(fileName);
  });
};

const req = require.context('../../assets/svgs', false, /.$/);
// load all svgs
requireAll(req);
