export enum EnumAPP {
  ShopeeMall = 1,
  Tiktok,
  Instagram,
  Facebook,
  Msha,
  Microsite
}

export enum EnumEnv {
  Test = 'Test',
  Uat = 'Uat',
  Staging = 'Staging',
  Live = 'Live',
  Dev = 'Dev',
}

export enum EnumOS {
  Others = 'Others',
  Android = 'Android',
  IOS = 'IOS',
}

export enum EnumRegion {
  BR = 'BR',
  MY = 'MY',
  VN = 'VN',
  SG = 'SG',
  TW = 'TW',
  PH = 'PH',
  ES = 'ES',
  MX = 'MX',
  PL = 'PL',
  CL = 'CL',
  CO = 'CO',
  TH = 'TH',
}

export interface SlideItem {
  img: string;
  openAppType?: EnumAPP;
  onClick?: () => void;
}

export const SLIDES: SlideItem[] = [
  {
    img: require('@/assets/imgs/banner-0.jpg'),
    openAppType: EnumAPP.ShopeeMall,
  },
  {
    img: require('@/assets/imgs/banner-4.jpg'),
    openAppType: EnumAPP.Instagram,
  },
  {
    img: require('@/assets/imgs/banner-5.jpg'),
    openAppType: EnumAPP.ShopeeMall,
  },
];

export const STYLE_SLIDES: SlideItem[] = [
  {
    img: require('@/assets/imgs/style-1.jpg'),
  },
  {
    img: require('@/assets/imgs/style-2.jpg'),
  },
  {
    img: require('@/assets/imgs/style-3.jpg'),
  },
  {
    img: require('@/assets/imgs/style-4.jpg'),
  },
];

export const PURPOSE_SLIDES: SlideItem[] = [
  {
    img: require('@/assets/imgs/mission.jpg'),
  },
  {
    img: require('@/assets/imgs/vision.jpg'),
  },
];

export const IMAGE_CARDS: {
  img: string;
  title: string;
  alt: string;
}[] = [
  {
    img: require('@/assets/imgs/category-cardigans.jpg'),
    title: 'Cardigans',
    alt: 'Cardigans',
  },
  {
    img: require('@/assets/imgs/category-blouses.jpg'),
    title: 'Blouses',
    alt: 'Blouses',
  },
  {
    img: require('@/assets/imgs/category-sweaters.jpg'),
    title: 'Sweaters',
    alt: 'Sweaters',
  },
  {
    img: require('@/assets/imgs/category-coats.jpg'),
    title: 'Coats',
    alt: 'Coats',
  },
  {
    img: require('@/assets/imgs/category-dresses.jpg'),
    title: 'Dresses',
    alt: 'Dresses',
  },
  {
    img: require('@/assets/imgs/category-blazers.jpg'),
    title: 'Blazers',
    alt: 'Blazers',
  },
];

export const KOL_SLIDES: SlideItem[] = [
  {
    img: require('@/assets/imgs/follow-us-1.jpg'),
  },
  {
    img: require('@/assets/imgs/follow-us-2.jpg'),
  },
  {
    img: require('@/assets/imgs/follow-us-3.jpg'),
  },
  {
    img: require('@/assets/imgs/follow-us-4.jpg'),
  },
  {
    img: require('@/assets/imgs/follow-us-5.jpg'),
  },
];

export const SHARED_SLIDES: SlideItem[] = [
  {
    img: require('@/assets/imgs/shared-1.jpg'),
  },
  {
    img: require('@/assets/imgs/shared-2.jpg'),
  },
  {
    img: require('@/assets/imgs/shared-3.jpg'),
  },
  {
    img: require('@/assets/imgs/shared-4.jpg'),
  },
  {
    img: require('@/assets/imgs/shared-5.jpg'),
  },
];

export const DIAMOND_SLIDES: SlideItem[] = [
  {
    img: require('@/assets/imgs/diamond-1.png'),
  },
  {
    img: require('@/assets/imgs/diamond-2.png'),
  },
  {
    img: require('@/assets/imgs/diamond-3.png'),
  },
];

export const CUSTOMER_SLIDES: SlideItem[] = [
  {
    img: require('@/assets/imgs/customer-1.jpg'),
  },
  {
    img: require('@/assets/imgs/customer-2.jpg'),
  },
  {
    img: require('@/assets/imgs/customer-3.jpg'),
  },
];

export const SHOPEE_MALL_INFO = {
  [EnumRegion.BR]: {
    domain: 'shopee.com.br',
    path: 'lovito.br',
  },
  [EnumRegion.MY]: {
    domain: 'shopee.com.my',
    path: 'lovito.os',
  },
  [EnumRegion.VN]: {
    domain: 'shopee.vn',
    path: 'lovito.vn',
  },
  [EnumRegion.SG]: {
    domain: 'shopee.sg',
    path: 'lovito.sg',
  },
  [EnumRegion.TW]: {
    domain: 'shopee.tw',
    path: 'lovito.tw',
  },
  [EnumRegion.PH]: {
    domain: 'shopee.ph',
    path: 'lovito.ph',
  },
  [EnumRegion.ES]: {
    domain: 'shopee.es',
    path: 'lovitors.es',
  },
  [EnumRegion.MX]: {
    domain: 'shopee.com.mx',
    path: 'lovito.mx',
  },
  [EnumRegion.PL]: {
    domain: 'shopee.pl',
    path: 'lovitofn.pl',
  },
  [EnumRegion.CL]: {
    domain: 'shopee.cl',
    path: 'lovito.cl',
  },
  [EnumRegion.CO]: {
    domain: 'shopee.com.co',
    path: 'lovito.co',
  },
  [EnumRegion.TH]: {
    domain: 'shopee.co.th',
    path: 'lovito.th',
  },
} as const;

export const MICROSITE_DICT = {
  [EnumRegion.BR]: 'https://shp.ee/ed62kwr',
  [EnumRegion.MY]: 'https://shp.ee/uihg2u3',
  [EnumRegion.VN]: 'https://shp.ee/xhr5qms',
  [EnumRegion.SG]: 'https://shp.ee/vf9b4t6',
  [EnumRegion.TW]: '',
  [EnumRegion.PH]: 'https://shp.ee/vf9b4t6',
  [EnumRegion.ES]: '',
  [EnumRegion.MX]: '',
  [EnumRegion.PL]: '',
  [EnumRegion.CL]: '',
  [EnumRegion.CO]: '',
  [EnumRegion.TH]: 'https://shp.ee/nx53qcg',
} as const;

type DeepLinkItem = Record<EnumOS, string> & {
  HTTP: string;
};

export const DEEP_LINK_MAP: Record<EnumAPP, DeepLinkItem> = {
  [EnumAPP.ShopeeMall]: {
    [EnumOS.Android]: 'https://{DOMAIN}/universal-link/{PATH}',
    [EnumOS.IOS]: 'https://{DOMAIN}/universal-link/{PATH}',
    [EnumOS.Others]: 'https://{DOMAIN}/universal-link/{PATH}',
    HTTP: 'https://{DOMAIN}/{PATH}',
  },
  [EnumAPP.Tiktok]: {
    [EnumOS.Android]: 'https://www.tiktok.com/@lovito_official',
    [EnumOS.IOS]: 'https://www.tiktok.com/@lovito_official',
    [EnumOS.Others]: 'https://www.tiktok.com/@lovito_official',
    HTTP: 'https://www.tiktok.com/@lovito_official',
  },
  [EnumAPP.Instagram]: {
    [EnumOS.Android]: 'instagram://user?username=lovito_official',
    [EnumOS.IOS]: 'instagram://user?username=lovito_official',
    [EnumOS.Others]: 'https://www.instagram.com/lovito_official/',
    HTTP: 'https://www.instagram.com/lovito_official/',
  },
  [EnumAPP.Facebook]: {
    [EnumOS.Android]: 'fb://page/109818568075529',
    [EnumOS.IOS]: 'fb://profile/109818568075529',
    [EnumOS.Others]: 'https://www.facebook.com/lovitoofficial/',
    HTTP: 'https://www.facebook.com/lovitoofficial/',
  },
  [EnumAPP.Msha]: {
    [EnumOS.Android]: 'https://bit.ly/3DMuwXj',
    [EnumOS.IOS]: 'https://bit.ly/3DMuwXj',
    [EnumOS.Others]: 'https://bit.ly/3DMuwXj',
    HTTP: 'https://bit.ly/3DMuwXj',
  },
  [EnumAPP.Microsite]: {
    [EnumOS.Android]: '',
    [EnumOS.IOS]: '',
    [EnumOS.Others]: '',
    HTTP: '',
  },
};

export const DEFAULT_REGION = EnumRegion.SG;
