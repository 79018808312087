






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Icon extends Vue {
  @Prop({ default: '' }) readonly icon!: string;
  @Prop({ default: '' }) readonly className!: string;

  private get iconName(): string {
    return `#icon-${this.icon}`;
  }
  private get svgClass(): string {
    if (this.className) {
      return `svg-icon ${this.className}`;
    } else {
      return 'svg-icon';
    }
  }
}
