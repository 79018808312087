


































import swiper, { SwiperOptions, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import 'swiper/swiper-bundle.css';
import { SlideItem } from '@/const';
import { EnumDataTracking } from '@/const/dataTracking';

swiper.use([Pagination, Autoplay]);

@Component({
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
})
export default class LovitoSwiper extends Vue {
  @Prop() private slides!: SlideItem[];
  swiperOption: SwiperOptions = {
    autoplay: {
      disableOnInteraction: false,
    },
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: false,
    },
  };

  EnumDataTracking = EnumDataTracking;

  @Emit()
  clickBanner(slide: SlideItem): SlideItem {
    return slide;
  }
}
