












import { Component, Prop, Vue } from 'vue-property-decorator';

import { EnumDataTracking } from '@/const/dataTracking';

@Component
export default class ImageCard extends Vue {
  @Prop() private img!: string;
  @Prop() private title!: string;
  @Prop() private desc!: string;
  @Prop({ default: '' }) private alt?: string;

  EnumDataTracking = EnumDataTracking;
}
