import { DEFAULT_REGION, EnumEnv, EnumOS, EnumRegion } from '@/const';

export function getEnv(): EnumEnv {
  const { host } = location;
  switch (host) {
    case 'lovito.com':
      return EnumEnv.Live;
    case 'test.lovito.com':
      return EnumEnv.Test;
    case 'uat.lovito.com':
      return EnumEnv.Uat;
    case 'staging.lovito.com':
      return EnumEnv.Staging;
    default:
      return EnumEnv.Dev;
  }
}

export function getAPIHost(): string {
  const env = getEnv();
  switch (env) {
    case EnumEnv.Test:
    case EnumEnv.Dev:
      return 'seller.test.shopee.sg';
    case EnumEnv.Uat:
      return 'seller.uat.shopee.sg';
    case EnumEnv.Staging:
      return 'seller.staging.shopee.sg';
    default:
      return 'seller.shopee.sg';
  }
}

export function getSurveyLink(): string {
  const env = getEnv();
  switch (env) {
    // need to config whistle rule:
    // https://seller.test.shopee.cn/ resHeaders://(X-Frame-Options=allow-fromhttp://localhost:8888/)
    case EnumEnv.Dev:
    case EnumEnv.Test:
      return 'https://seller.test.shopee.cn/toolsets/iframe/survey?independent_id=89757bd4a22e07fe888a52b7a2c5f24e';
    case EnumEnv.Uat:
      return 'https://seller.uat.shopee.cn/toolsets/iframe/survey?independent_id=ff8f4edae0496cd56238e2dc8955d30d';
    case EnumEnv.Staging:
      return 'https://seller.staging.shopee.cn/toolsets/iframe/survey?independent_id=1dad2c8b4c612fc3fa5ff8b04efc6321';
    default:
      return 'https://seller.shopee.cn/toolsets/iframe/survey?independent_id=fe7a110cafaf51497fd78d0908c9c3bc';
  }
}

export function getOS(): EnumOS {
  const { userAgent } = navigator;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return EnumOS.Others;
  }

  if (/android/i.test(userAgent)) {
    return EnumOS.Android;
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return EnumOS.IOS;
  }

  return EnumOS.Others;
}

export async function getRegion(): Promise<EnumRegion> {
  try {
    const regionAPI = `${process.env.VUE_APP_DOMAIN}/api/get_region`;
    const res = (await fetch(regionAPI).then((response) =>
      response.json()
    )) as {
      code: number;
      message: string;
      data: { region: EnumRegion };
    };
    return res.data.region || DEFAULT_REGION;
  } catch {
    return DEFAULT_REGION;
  }
}
