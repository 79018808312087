







import { Component, Vue } from 'vue-property-decorator';

import { getSurveyLink } from '../utils/environment';

@Component
export default class Contact extends Vue {
  showIframe = false;

  get surveyLink(): string {
    return getSurveyLink();
  }

  mounted(): void {
    window.addEventListener('message', (e) => {
      if (!e.data || !e.origin.match(/https:\/\/seller\.[\s\S]*shopee\.cn/))
        return;

      const data = JSON.parse(e.data);

      if (
        data &&
        data.from &&
        data.from === 'surveyIframe' &&
        data.status === 'success'
      ) {
        this.showIframe = true;

        const containerDom = this.$refs.containerDom as HTMLElement;

        // set the height as actual visual height
        containerDom.setAttribute(
          'style',
          `height: ${document.documentElement.clientHeight}px;`
        );
      }
    });
  }
}
