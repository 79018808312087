











import swiper, { Autoplay, Pagination, SwiperOptions } from 'swiper';
import { directive } from 'vue-awesome-swiper';
import { Component, Prop, Vue } from 'vue-property-decorator';

import 'swiper/swiper-bundle.css';
import { SlideItem } from '@/const';

swiper.use([Pagination, Autoplay]);

@Component({
  directives: {
    swiper: directive,
  },
})
export default class SharedSwiper extends Vue {
  @Prop() private slides!: SlideItem[];

  swiperOption: SwiperOptions = {
    slidesPerView: 'auto',
    centeredSlides: true,
    autoplay: {
      disableOnInteraction: false,
    },
    loop: true,
    spaceBetween: '2.12%' as unknown as number, // spaceBetween should support percentage string, add "as" to pass build
    pagination: false
  };
}
