import {
  DEEP_LINK_MAP,
  EnumAPP,
  EnumOS,
  EnumRegion,
  SHOPEE_MALL_INFO,
  MICROSITE_DICT,
} from '@/const';

function openDeepLink(link: string, fallback: () => void): void {
  location.href = link;

  // app was open successfully, not need to fallback
  const fallbackTimer = setTimeout(() => {
    fallback();
    removeDetectSideEffect();
  }, 2800);

  function clearFallbackTimer(): void {
    clearTimeout(fallbackTimer);
    removeDetectSideEffect();
  }

  const visibilitychange = function () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const tag = document.hidden || document.webkitHidden;
    tag && clearFallbackTimer();
  };

  function detectAppOpen(): void {
    document.addEventListener('visibilitychange', visibilitychange);
    document.addEventListener('webkitvisibilitychange', visibilitychange);
    window.addEventListener('pagehide', clearFallbackTimer);
  }

  function removeDetectSideEffect(): void {
    document.removeEventListener('visibilitychange', visibilitychange);
    document.removeEventListener('webkitvisibilitychange', visibilitychange);
    window.removeEventListener('pagehide', clearFallbackTimer);
  }

  detectAppOpen();
}

export function openAPP(app: EnumAPP, OS: EnumOS, region: EnumRegion): void {
  const deepLinkItem = DEEP_LINK_MAP[app];
  let appLink = deepLinkItem[OS];
  let HTTPLink = deepLinkItem.HTTP;
  if (app === EnumAPP.ShopeeMall) {
    const { domain, path } = SHOPEE_MALL_INFO[region];
    [appLink, HTTPLink] = [appLink, HTTPLink].map((item) =>
      item.replace('{DOMAIN}', domain).replace('{PATH}', path)
    );
  } else if (app === EnumAPP.Microsite) {
    appLink = MICROSITE_DICT[region];
    HTTPLink = MICROSITE_DICT[region];
  }

  openDeepLink(appLink, () => (location.href = HTTPLink));
}
